@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}


.notfound {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
.navbar {
    background-color: #060b26;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
  
.menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
}

.nav-menu {
    background-color: #060b26;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index: 999;
}

.nav-menu.active {
    left: 0;
    transition: 350ms;
    
}

.nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 8px 8px;
    list-style: none;
    height: 60px;
}

.nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
}

.nav-text a:hover {
    background-color: #1a83ff;
    
}

.nav-menu-items {
    width: 100%;
    
}

.navbar-toggle {
    background-color: #060b26;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.nav-text span {
    margin-left: 16px;
}
.icon{
    width:100%;
    display:block;
}
.icon{
    cursor: default;
}
.icon span{
    float: right;
    margin-right: 10px;
    color: #f5f5f5;
    font-size: 18px;
}
.icon span:hover{
    cursor: pointer;
}
.viewer-row{
    display: flex;
    grid-column-gap: 10px;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    grid-row-gap: 10px;
    grid-row-gap: 10px;
    row-gap: 10px;
    margin: 10px 0px 10px 10px;
}

.viewer-column{
    width: calc(calc(100% / 4) - 10px);
    border: 1px solid black;
    height:400px;
    border-radius:5px;
    position: relative;
}
@media screen and (max-width: 450px) {
    .viewer-column{
        width: calc(100% - 10px);
    }
}
@media (min-width:451px) and (max-width: 640px) {
    .viewer-column{
        width: calc(calc(100% / 2) - 10px);
    }
}
@media (min-width:641px) and (max-width: 991px) {
    .viewer-column{
        width: calc(calc(100% / 2) - 10px);
    }
}
.viewer-column-image img{
    width: 100%;
    height: 200px;
}
.viewer-column-title{
    margin: 0px 5px;
}
.viewer-column-title div a{
    text-decoration: none;
}
.viewer-column-title div a{
    width:100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: justify;
}
.viewer-column-description{
    margin: 1px 5px;
}
.viewer-column-description div{
    width:100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    text-align: justify;
}
.viewer-column-url {
    position: absolute;
    bottom: 2px;
    right: 2px;
}
.viewer-column-url div span{
    margin:0px;
    font-size: 13px;
}
.viewer-column-url div span:hover{
    cursor: pointer;
}

.pagination,.search-container {
    display: flex;
    justify-content: center;
  }
  .pagination-button,.search-container-details{
    display: inline-block;
    margin:10px;
    
  }
  .pagination-button button {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    margin:0px 10px;
  }
  
  .pagination-button button.active {
    background-color: #4CAF50;
    color: white;
  }
  
  .pagination-button button:hover:not(.active) {background-color: #ddd;}

  .search-container-details input,.search-container-details button{
    font-size:20px;
    margin:5px;
    padding: 2px 5px;
  }
.loaders{
text-align: center;
}
.search h1{
  margin:10px;
}
.topheadlines-container-heading h1{
    margin:10px;
}

.topheadlines-container-heading{
    display:flex;
}
.country{
    margin: auto 15px auto auto;
}

.loading {
  font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
  text-transform:uppercase;
  
  width:150px;
  text-align:center;
  line-height:50px;
  
  position:absolute;
  left:0;right:0;top:50%;
  margin:auto;
  transform:translateY(-50%);
}

.loading span {
  position:relative;
  z-index:999;
  color:#fff;
}
.loading:before {
  content:'';
  background:#1a83ff;
  width:128px;
  height:36px;
  display:block;
  position:absolute;
  top:0;left:0;right:0;bottom:0;
  margin:auto;
  
  animation:2s loadingBefore infinite ease-in-out;
}

@keyframes loadingBefore {
  0%   {transform:translateX(-14px);}
  50%  {transform:translateX(14px);}
  100% {transform:translateX(-14px);}
}


.loading:after {
  content:'';
  background:#060b26;
  width:14px;
  height:60px;
  display:block;
  position:absolute;
  top:0;left:0;right:0;bottom:0;
  margin:auto;
  opacity:.5;
  
  animation:2s loadingAfter infinite ease-in-out;
}

@keyframes loadingAfter {
  0%   {transform:translateX(-50px);}
  50%  {transform:translateX(50px);}
  100% {transform:translateX(-50px);}
}
