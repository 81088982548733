.viewer-row{
    display: flex;
    grid-column-gap: 10px;
    column-gap: 10px;
    grid-row-gap: 10px;
    row-gap: 10px;
    margin: 10px 0px 10px 10px;
}

.viewer-column{
    width: calc(calc(100% / 4) - 10px);
    border: 1px solid black;
    height:400px;
    border-radius:5px;
    position: relative;
}
@media screen and (max-width: 450px) {
    .viewer-column{
        width: calc(100% - 10px);
    }
}
@media (min-width:451px) and (max-width: 640px) {
    .viewer-column{
        width: calc(calc(100% / 2) - 10px);
    }
}
@media (min-width:641px) and (max-width: 991px) {
    .viewer-column{
        width: calc(calc(100% / 2) - 10px);
    }
}
.viewer-column-image img{
    width: 100%;
    height: 200px;
}
.viewer-column-title{
    margin: 0px 5px;
}
.viewer-column-title div a{
    text-decoration: none;
}
.viewer-column-title div a{
    width:100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: justify;
}
.viewer-column-description{
    margin: 1px 5px;
}
.viewer-column-description div{
    width:100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    text-align: justify;
}
.viewer-column-url {
    position: absolute;
    bottom: 2px;
    right: 2px;
}
.viewer-column-url div span{
    margin:0px;
    font-size: 13px;
}
.viewer-column-url div span:hover{
    cursor: pointer;
}
